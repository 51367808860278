export enum privilegesEnum {
  READ_USERS = "READ_USERS",
  WRITE_USERS = "WRITE_USERS",
  DELETE_USERS = "DELETE_USERS",
  MODIFY_ORGANIZATION = "MODIFY_ORGANIZATION",
  READ_ROLES = "READ_ROLES",
  WRITE_ROLES = "WRITE_ROLES",
  DELETE_ROLES = "DELETE_ROLES",
  ASSIGN_ROLES = "ASSIGN_ROLES",
  READ_PRIVILEGES = "READ_PRIVILEGES",
  READ_BUSINESS_UNITS = "READ_BUSINESS_UNITS",
  WRITE_BUSINESS_UNITS = "WRITE_BUSINESS_UNITS",
  DELETE_BUSINESS_UNITS = "DELETE_BUSINESS_UNITS",
  READ_CATEGORIES = "READ_CATEGORIES",
  WRITE_CATEGORIES = "WRITE_CATEGORIES",
  DELETE_CATEGORIES = "DELETE_CATEGORIES",
  READ_ATTRIBUTES = "READ_ATTRIBUTES",
  WRITE_ATTRIBUTES = "WRITE_ATTRIBUTES",
  DELETE_ATTRIBUTES = "DELETE_ATTRIBUTES",
  READ_ARTICLES = "READ_ARTICLES",
  WRITE_ARTICLES = "WRITE_ARTICLES",
  DELETE_ARTICLES = "DELETE_ARTICLES",
  READ_SUPPLIERS = "READ_SUPPLIERS",
  WRITE_SUPPLIERS = "WRITE_SUPPLIERS",
  DELETE_SUPPLIERS = "DELETE_SUPPLIERS",
  READ_DEPOSITS = "READ_DEPOSITS",
  WRITE_DEPOSITS = "WRITE_DEPOSITS",
  DELETE_DEPOSITS = "DELETE_DEPOSITS",
  READ_PROMOTION_TYPES = "READ_PROMOTION_TYPES",
  WRITE_PROMOTION_TYPES = "WRITE_PROMOTION_TYPES",
  DELETE_PROMOTION_TYPES = "DELETE_PROMOTION_TYPES",
  READ_PRICING_TYPES = "READ_PRICING_TYPES",
  WRITE_PRICING_TYPES = "WRITE_PRICING_TYPES",
  DELETE_PRICING_TYPES = "DELETE_PRICING_TYPES",
  READ_COMPETITORS = "READ_COMPETITORS",
  WRITE_COMPETITORS = "WRITE_COMPETITORS",
  DELETE_COMPETITORS = "DELETE_COMPETITORS",
  READ_PURCHASE_ORDERS = "READ_PURCHASE_ORDERS",
  WRITE_PURCHASE_ORDERS = "WRITE_PURCHASE_ORDERS",
  DELETE_PURCHASE_ORDERS = "DELETE_PURCHASE_ORDERS",
  READ_REPOSITION_ORDERS = "READ_REPOSITION_ORDERS",
  WRITE_REPOSITION_ORDERS = "WRITE_REPOSITION_ORDERS",
  DELETE_REPOSITION_ORDERS = "DELETE_REPOSITION_ORDERS",
  READ_CLIENTS = "READ_CLIENTS",
  WRITE_CLIENTS = "WRITE_CLIENTS",
  DELETE_CLIENTS = "DELETE_CLIENTS",
  READ_PROMOTIONS = "READ_PROMOTIONS",
  WRITE_PROMOTIONS = "WRITE_PROMOTIONS",
  DELETE_PROMOTIONS = "DELETE_PROMOTIONS",
  READ_BRANDS = "READ_BRANDS",
  WRITE_BRANDS = "WRITE_BRANDS",
  DELETE_BRANDS = "DELETE_BRANDS",
  READ_TICKETS = "READ_TICKETS",
  WRITE_TICKETS = "WRITE_TICKETS",
  DELETE_TICKETS = "DELETE_TICKETS",
  READ_PRICINGS = "READ_PRICINGS",
  WRITE_PRICINGS = "WRITE_PRICINGS",
  DELETE_PRICINGS = "DELETE_PRICINGS",
  READ_ACCEPTED_PAYMENT_METHODS = "READ_ACCEPTED_PAYMENT_METHODS",
  WRITE_ACCEPTED_PAYMENT_METHODS = "WRITE_ACCEPTED_PAYMENT_METHODS",
  DELETE_ACCEPTED_PAYMENT_METHODS = "DELETE_ACCEPTED_PAYMENT_METHODS",
  READ_BRANCH_OFFICES = "READ_BRANCH_OFFICES",
  WRITE_BRANCH_OFFICES = "WRITE_BRANCH_OFFICES",
  DELETE_BRANCH_OFFICES = "DELETE_BRANCH_OFFICES",
  READ_SUPPLIER_PAYMENT_METHODS = "READ_SUPPLIER_PAYMENT_METHODS",
  WRITE_SUPPLIER_PAYMENT_METHODS = "WRITE_SUPPLIER_PAYMENT_METHODS",
  DELETE_SUPPLIER_PAYMENT_METHODS = "DELETE_SUPPLIER_PAYMENT_METHODS",
  READ_PURCHASE_UNITS = "READ_PURCHASE_UNITS",
  WRITE_PURCHASE_UNITS = "WRITE_PURCHASE_UNITS",
  DELETE_PURCHASE_UNITS = "DELETE_PURCHASE_UNITS",
  READ_PARAMETERS = "READ_PARAMETERS",
  WRITE_PARAMETERS = "WRITE_PARAMETERS",
  DELETE_PARAMETERS = "DELETE_PARAMETERS",
  READ_COLOURS = "READ_COLOURS",
  WRITE_COLOURS = "WRITE_COLOURS",
  DELETE_COLOURS = "DELETE_COLOURS",
  READ_GOALS = "READ_GOALS",
  WRITE_GOALS = "WRITE_GOALS",
  DELETE_GOALS = "DELETE_GOALS",
  READ_CLUSTERS = "READ_CLUSTERS",
  WRITE_CLUSTERS = "WRITE_CLUSTERS",
  DELETE_CLUSTERS = "DELETE_CLUSTERS",
  READ_VEHICLES = "READ_VEHICLES",
  WRITE_VEHICLES = "WRITE_VEHICLES",
  DELETE_VEHICLES = "DELETE_VEHICLES",
  READ_CURVES = "READ_CURVES",
  WRITE_CURVES = "WRITE_CURVES",
  DELETE_CURVES = "DELETE_CURVES",
}

export const hasPermission = (permission: any) => {
  const privileges = JSON.parse(localStorage.getItem("privileges")!);

  return (
    privileges.find((privilege: any) => privilege.name === permission) !==
    undefined
  );
};
