import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  createDeposit,
  getAllBranchOfficesByBu,
  getCategoriesFiltered,
} from "../../../api/Services";
import { BusinessUniteContext } from "../../../components/laoyut/Layout";
import { LoadingOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  InputNumber,
  message,
  Select,
  TreeSelect,
  Spin,
} from "antd";

import styles from "./NewDeposit.module.scss";

const NewDeposit = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { contextValue: businessUnitSelected } =
    useContext(BusinessUniteContext);

  const [loading, setLoading] = useState(false);
  const [branchOffices, setBranchOffices] = useState<any>([]);
  const [categories, setCategories] = useState<any[]>([]);
  const [selectedBranchOffices, setSelectedBranchOffices] = useState<any[]>([]);

  const handleBranchOfficeChange = (value: any) => {
    setSelectedBranchOffices(value);
  };

  const renderBranchOfficeDistanceFields = () => {
    return selectedBranchOffices.map((branchOfficeId) => {
      let foundBranchOffice: any = null;

      branchOffices.some((businessUnit: any) => {
        foundBranchOffice = businessUnit.branchOffices.find(
          (office: any) => office.id === branchOfficeId
        );
        return !!foundBranchOffice;
      });

      return (
        <Form.Item
          key={branchOfficeId}
          label={`${t("Common-Distance")} ${foundBranchOffice?.name}`}
          name={`distance-${branchOfficeId}`}
        >
          <InputNumber />
        </Form.Item>
      );
    });
  };

  const [form] = Form.useForm();

  useEffect(() => {
    handleFetchBranchOffices();
  }, [businessUnitSelected]);

  const handleSearchCategories = async (value: string) => {
    if (value.length > 2) {
      const response = await getCategoriesFiltered(value);
      setCategories(response);
    } else setCategories([]);
  };

  const handleFetchBranchOffices = async () => {
    const response = await getAllBranchOfficesByBu([businessUnitSelected]);
    setBranchOffices(response);
  };

  const getOptionsCategories = (items: any) => {
    const options: any[] = [];

    items?.forEach(function (item: any) {
      const parentsString = item?.parents
        ? item.parents.filter(Boolean).join(" - ")
        : "";
      const categoryName = item?.name || "";

      const categoryTree = parentsString
        ? `${parentsString} - ${categoryName}`
        : categoryName;

      options.push(
        <Select.Option value={item.id} key={item.id}>
          {categoryTree}
        </Select.Option>
      );
    });

    return options;
  };
  const getOptionsTree = (items: any) => {
    const options: any[] = [];

    items?.forEach(function (item: any) {
      options.push({
        title: item.businessUnit.name,
        value: item.businessUnit.name,
        key: item.businessUnit.name,
        children: item.branchOffices.map((bu: any) => ({
          title: bu.name,
          value: bu.id,
          key: bu.id,
        })),
      });
    });

    return options;
  };

  const handleCreateDeposit = async () => {
    await form.validateFields().then(async (data) => {
      setLoading(true);
      const {
        name,
        capacity,
        externalId,
        branchOfficesIds,
        address,
        latitude,
        longitude,
        categoriesIds,
        capacityInUnits,
        capacityDispatchUnits,
        dispatchFrequency,
        capacityReception,
        storageCostPerUnit,
        minimumStock,
        maximumStock,
      } = data;

      const branchOfficesDeposit = branchOfficesIds?.map((id: any) => ({
        branchOfficeId: id,
        distance: data[`distance-${id}`],
      }));

      let response = await createDeposit({
        name,
        capacity,
        externalId,
        branchOffices: branchOfficesDeposit ?? [],
        address,
        latitude,
        longitude,
        businessUnitsIds: [businessUnitSelected],
        categoriesIds: categoriesIds ?? [],
        capacityInUnits,
        capacityDispatchUnits,
        dispatchFrequency,
        capacityReception,
        storageCostPerUnit,
        minimumStock,
        maximumStock,
      });

      if (response.success) {
        message.success(t("Common-CreateSuccessMessage"));
        form.resetFields();
        navigate(`/admin/deposits/${response.deposit.id}/view`);
      } else message.error(t("Common-ErrorMessage"));

      setLoading(false);
    });
  };

  return loading ? (
    <Spin
      indicator={<LoadingOutlined className="ol-loading-spin-icon" />}
      delay={500}
    />
  ) : (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Common-NewDeposit")}</h1>
        <Button
          onClick={handleCreateDeposit}
          className={styles.saveButton}
          type="primary"
        >
          {t("Common-Add")}
        </Button>
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.newWrapper}>
          <Form
            name="deposit"
            layout="vertical"
            initialValues={{ remember: true }}
            form={form}
            autoComplete="off"
            className={styles.formNew}
          >
            <Form.Item
              className={styles.newItem}
              label={t("Common-Name")}
              name="name"
              rules={[
                {
                  required: true,
                  message: t("Common-Name"),
                },
              ]}
            >
              <Input placeholder={t("Common-Name")} />
            </Form.Item>
            <Form.Item
              label={t("Common-ExternalId")}
              name="externalId"
              className={styles.newItem}
            >
              <Input placeholder={t("Common-ExternalId")} />
            </Form.Item>
            <Form.Item
              className={styles.newItem}
              label={t("Common-CapacityStorageInmt3")}
              name="capacity"
              rules={[
                {
                  required: true,
                  message: t("Common-CapacityStorageInmt3"),
                },
              ]}
            >
              <InputNumber
                placeholder={t("Common-CapacityStorageInmt3")}
                addonAfter={t("Common-mtcube")}
                className={styles.inputNumber}
              />
            </Form.Item>
            <Form.Item
              className={styles.newItem}
              label={t("Common-CapacityStorageInUnits")}
              name="capacityInUnits"
            >
              <InputNumber
                placeholder={t("Common-CapacityStorageInUnits")}
                className={styles.inputNumber}
              />
            </Form.Item>
            <Form.Item
              className={styles.newItem}
              label={t("Common-CapacityDispatchUnits")}
              name="capacityDispatchUnits"
            >
              <InputNumber
                placeholder={t("Common-CapacityDispatchUnits")}
                className={styles.inputNumber}
              />
            </Form.Item>
            <Form.Item
              className={styles.newItem}
              label={t("Common-CapacityReception")}
              name="capacityReception"
            >
              <InputNumber
                placeholder={t("Common-CapacityReception")}
                className={styles.inputNumber}
              />
            </Form.Item>
            <Form.Item
              className={styles.newItem}
              label={t("Common-StorageCostPerUnit")}
              name="storageCostPerUnit"
            >
              <InputNumber
                placeholder={t("Common-StorageCostPerUnit")}
                className={styles.inputNumber}
              />
            </Form.Item>
            <Form.Item
              label={t("Common-MinimumStock")}
              name="minimumStock"
              className={styles.newItem}
            >
              <InputNumber
                placeholder={t("Common-MinimumStock")}
                className={styles.inputNumber}
              />
            </Form.Item>
            <Form.Item
              label={t("Common-MaximumStock")}
              name="maximumStock"
              className={styles.newItem}
            >
              <InputNumber
                placeholder={t("Common-MaximumStock")}
                className={styles.inputNumber}
              />
            </Form.Item>
            <Form.Item
              className={styles.newItem}
              label={t("Common-DispatchFrequency")}
              name="dispatchFrequency"
            >
              <InputNumber
                min={1}
                max={7}
                placeholder={t("Common-DispatchFrequency")}
                className={styles.inputNumber}
              />
            </Form.Item>
            <Form.Item
              label={t("Common-Address")}
              name="address"
              className={styles.newItem}
            >
              <Input placeholder={t("Common-Address")} />
            </Form.Item>
            <Form.Item
              label={t("Common-Latitude")}
              name="latitude"
              className={styles.newItem}
            >
              <InputNumber
                className={styles.inputNumber}
                placeholder={t("Common-Latitude")}
              />
            </Form.Item>
            <Form.Item
              label={t("Common-Longitude")}
              name="longitude"
              className={styles.newItem}
            >
              <InputNumber
                className={styles.inputNumber}
                placeholder={t("Common-Longitude")}
              />
            </Form.Item>
            <Form.Item
              className={styles.newItem}
              label={t("Common-BranchOffices")}
              name="branchOfficesIds"
            >
              <TreeSelect
                allowClear
                placeholder={t("Common-BranchOffices")}
                treeCheckable={true}
                onChange={handleBranchOfficeChange}
                treeData={getOptionsTree(branchOffices)}
              />
            </Form.Item>
            <div className={styles.distanceWrapper}>
              {renderBranchOfficeDistanceFields()}
            </div>
            <Form.Item
              label={t("Common-Categories")}
              name="categoriesIds"
              className={styles.newItem}
            >
              <Select
                mode="multiple"
                allowClear
                onSearch={handleSearchCategories}
                placeholder={t("Common-SearchByName")}
                filterOption={(input: string, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {getOptionsCategories(categories)}
              </Select>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default NewDeposit;
